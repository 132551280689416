<template>
  <div class="join center">
    <div class="baseBox">
      <div class="joinBox">
        <router-link to="/" class="logo">
          <img src="@/assets/images/logo.png" alt="logo" />
        </router-link>
        <h2>Change Password</h2>
        <div class="form">
          <div class="flex">
            <p>
              New Password
              <span>*</span>
            </p>
            <div class="password">
              <input type="password" v-model="password" />
            </div>
          </div>
          <div class="flex">
            <p>
              Repeat Password
              <span>*</span>
            </p>
            <div class="password">
              <input type="password" v-model="passwordConfirm" />
            </div>
          </div>
        </div>
        <div class="buttonWrap">
          <button class="point large" @click="submit">Change Password</button>
          <!-- <router-link to="/login">
            <button class="cancel large pointOut">Cancel</button>
          </router-link> -->
          <a href="/login">
            <button class="cancel large pointOut">Cancel</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { updatePwd } from "@/api/index";
// import { mapState } from "vuex";
export default {
  mixins: [format],
  data() {
    return {
      password: "",
      passwordConfirm: "",
    };
  },
  // computed: {
  //   ...mapState(["username", "mobileNo", "email"]),
  // },
  created() {
    this.paramscheck();
  },
  methods: {
    paramscheck() {
      if (
        this.$route.params.email == "" ||
        this.$route.params.email == undefined
      ) {
        this.$router.push("/login");
      }
    },
    submit() {
      if (this.password == "") {
        return alert("Enter password.");
      } else if (this.checkPassword(this.password) == false) {
        return;
      } else if (this.password != this.passwordConfirm) {
        return alert("Password you entered is invalid.");
      }

      let data = {
        email: this.$route.params.email,
        password: this.password,
      };
      updatePwd(data).then((res) => {
        if (res.data.status == 200) {
          this.$router.push("/changePasswordDone");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
